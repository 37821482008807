import React, {useState, useEffect} from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import _ from 'lodash';

import ClickContainer from './ClickContainer';
import {ClearIcon, ChevronIcon} from './svg';
import {useWindowSize} from '../hooks';

const modalScreenMinSize = 768;

export const defaultNonNativeOptionsStyles = {
  width: 300,
  maxWidth: 300,
  top: 48,
  maxHeight: 500,
};

const NativeOptions = ({options, active, isGrouped}) => (isGrouped ? (
  <>
    {options.map((optionGroup) => (
      <optgroup label={optionGroup.label}>
        {optionGroup.options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            selected={active && active.value === option.value ? 'selected' : null}
          >
            {option.label}
          </option>
        ))}
      </optgroup>
    ))}
  </>
) : (
  <>
    {options.map((option) => (
      <option
        key={option.value}
        value={option.value}
        selected={active && active.value === option.value ? 'selected' : null}
      >
        {option.label}
      </option>
    ))}
  </>
));

const NonNativeOptions = ({
  options, active, filter, onClick, isGrouped,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    const cleanFilter = (filter || '').trim().toLowerCase();
    const matchesFilter = ({label}) => label.toLowerCase().includes(cleanFilter);

    let filtered;
    if (isGrouped) {
      const optionGroups = options.map((optionGroup) => ({
        label: optionGroup.label,
        options: optionGroup.options.filter(matchesFilter),
      }));
      filtered = optionGroups.filter((optionGroup) => optionGroup.options.length);
    } else {
      filtered = options.filter(matchesFilter);
    }
    setFilteredOptions(filtered);
  }, [options, filter]);

  return (
    <>
      {isGrouped ? (filteredOptions.map((optionGroup) => (
        <>
          <div className="pt-4 pb-2 px-5 text-xs text-gray-400 font-semibold uppercase">
            {optionGroup.label}
          </div>
          {optionGroup.options.map((option) => (
            <DropdownItem
              option={option}
              active={active}
              onClick={() => onClick(option.value)}
              key={option.value}
            />
          ))}
        </>
      ))) : (
        filteredOptions.map((option) => (
          <DropdownItem
            option={option}
            active={active}
            onClick={() => onClick(option.value)}
            key={option.value}
          />
        )))}
      {filter && filteredOptions.length === 0 && (
        <span className="px-4 pt-2 pb-4 font-medium text-gray-600">
          {`No results for "${filter}"`}
        </span>
      )}
    </>
  );
};

const DropdownInput = ({
  value,
  placeholder,
  options = [],
  native,
  onChange,
  disabled,
  showFilter,
  showReset = true,
  highlightActive = true,
  className,
  useModalForSmallScreens = true,
  truncateLength = null,
  optionsStyles = defaultNonNativeOptionsStyles,
  align,
}) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState();
  const {width} = useWindowSize();

  const useModal = useModalForSmallScreens && width <= modalScreenMinSize;

  useEffect(() => {
    if (disabled) setOpen(false);
  }, [disabled]);

  const numOptionsSelected = Array.isArray(value) ? value.length : 0;
  const isGrouped = _.every(options, 'options');
  let active;
  if (isGrouped) {
    const allOptions = _.flatMap(options, 'options');
    active = _.isArray(value)
      ? allOptions.filter((o) => value.includes(o.value))
      : allOptions.find((o) => value === o.value);
  } else {
    active = _.isArray(value)
      ? options.filter((o) => value.includes(o.value))
      : options.find((o) => value === o.value);
  }

  const handleClick = (val) => {
    if (!disabled) {
      onChange(val);
      setOpen(false);
    }
  };

  const handleReset = (e) => {
    if (!disabled) {
      e.stopPropagation();
      onChange(null);
      setOpen(false);
    }
  };

  const handleFilter = (e) => {
    if (!disabled) {
      setFilter(e.target.value);
    }
  };

  const getDisplayValue = () => {
    if (active && numOptionsSelected > 1) {
      return `${numOptionsSelected} Selected`;
    }
    if (active) {
      return _.truncate(active.label, {length: truncateLength || active.label.length});
    }
    return placeholder;
  };

  const displayValue = getDisplayValue();

  return (
    <div className={cx('relative', className)}>
      <button
        className={cx(`
          inline-flex
          items-center
          justify-between
          w-full
          rounded-lg
          border
          border-gray-300
          shadow-sm
          pl-4
          pr-2
          py-2
          bg-white
          text-sm
          font-medium
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-offset-gray-100
          focus:ring-indigo-500
          whitespace-nowrap
          transition
          duration-50
        `, {
          'border-indigo text-indigo': active && !disabled && highlightActive,
          'border-gray-300 text-gray-700 hover:border-gray-400 focus:border-gray-400': (!active || !highlightActive) && !disabled,
          'cursor-pointer': !disabled,
          'text-gray-400 cursor-default': disabled,
        })}
        onClick={() => !disabled && setOpen(!open)}
        type="button"
      >
        {native && (
          <select
            className={cx('h-full w-full absolute', {'cursor-pointer': !disabled, 'cursor-default': disabled})}
            onChange={(e) => handleClick(e.target.value)}
            style={{opacity: 0}}
            disabled={disabled}
            aria-label="Select"
          >
            <NativeOptions options={options} active={active} />
          </select>
        )}
        <span className="mr-1">{displayValue}</span>
        <ChevronIcon className="ml-auto" />
      </button>
      {active && showReset && (
        <button
          className={cx('absolute focus:outline-none z-10', {'text-gray-400': disabled, 'text-indigo-700': !disabled})}
          onClick={handleReset}
          type="button"
          aria-label="Clear"
          style={{right: 10, top: 7}}
        >
          <ClearIcon />
        </button>
      )}
      {!native && !useModal && (
        <ClickContainer onClose={open ? () => setOpen(false) : null}>
          <ul
            className={cx(
              'absolute flex flex-col bg-white border border-gray-200 shadow-lg rounded-lg z-10 overflow-auto',
              {'right-0': align === 'right', visible: open, invisible: !open},
            )}
            style={optionsStyles}
          >
            {showFilter && (
              <input
                type="text"
                placeholder="Filter..."
                defaultValue={filter}
                className="flex mt-3 mb-1 mx-3 px-2 py-2 border border-gray-300 rounded focus:outline-none focus:border-gray-400"
                onChange={handleFilter}
              />
            )}
            <NonNativeOptions
              options={options}
              active={active}
              filter={filter}
              onClick={handleClick}
              isGrouped={isGrouped}
            />
          </ul>
        </ClickContainer>
      )}
      {useModal && (
        <Modal
          className={cx('z-20 fixed bottom-0 left-0 right-0 pt-3 w-full h-96 max-h-screen overflow-scroll bg-white border border-gray-200 shadow-lg rounded-lg',
            {invisible: !open})}
          overlayClassName={open ? 'z-10 bg-black bg-opacity-10 fixed inset-0' : 'invisible'}
          shouldCloseOnOverlayClick
          onRequestClose={() => setOpen(false)}
          isOpen
        >
          <ul className="flex flex-col">
            {showFilter && (
              <input
                type="text"
                placeholder="Filter..."
                defaultValue={filter}
                className="flex mb-1 mx-3 px-2 py-2 border border-gray-300 rounded focus:outline-none focus:border-gray-400"
                onChange={handleFilter}
              />
            )}
            <NonNativeOptions
              options={options}
              active={active}
              filter={filter}
              onClick={handleClick}
              isGrouped={isGrouped}
            />
          </ul>
        </Modal>
      )}
    </div>
  );
};

const DropdownItem = ({option, active, onClick}) => {
  const isActive = _.isArray(active)
    ? _.some(active, (activeItem) => activeItem.value === option.value)
    : active && option.value === active.value;

  const aClickHandler = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <li>
      {option.url ? (
        <a
          className={cx('block w-full py-3 px-5 cursor-pointer hover:bg-gray-50 focus:bg-gray-50 font-medium text-base text-left whitespace-pre-wrap', {
            'text-indigo': isActive,
          })}
          onClick={aClickHandler}
          href={option.url}
        >
          {option.label}
        </a>
      ) : (
        <button
          className={cx('w-full py-3 px-5 cursor-pointer hover:bg-gray-50 focus:bg-gray-50 font-medium text-base text-left whitespace-pre-wrap', {
            'text-indigo': isActive,
          })}
          onClick={onClick}
          type="button"
        >
          {option.label}
        </button>
      )}
    </li>
  );
};

export default DropdownInput;
